import { NumericFormat } from "react-number-format";
import { Table } from "react-bootstrap";

function Projections({ cases, integration, setIntegration, usage, stage }) {
  return (
    <>
      <div className="mt-5 mb-0">
        <small className="text-muted">
          Projection multipliers created through statistical analysis of real
          customer data.
        </small>
      </div>
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th className="col-1">Case</th>
            <th className="col-1">AOV+</th>
            <th className="col-1">CR+</th>
            <th className="col-2">Monthly Revenue</th>
            {stage === 5 ? (
              <>
                <th className="col-1">Payback Period</th>
                <th className="col-1">Monthly ROI</th>
              </>
            ) : undefined}
          </tr>
        </thead>
        <tbody>
          {cases.map((record, idx) => (
            <tr
              key={idx}
              className={record.variant ? `table-${record.variant}` : ""}
            >
              <td>{record.name}</td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={record.aov * 100}
                  decimalScale={0}
                  thousandSeparator={true}
                  suffix={"%"}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={record.conversionRate * 100}
                  decimalScale={0}
                  thousandSeparator={true}
                  suffix={"%"}
                />
              </td>
              <td>
                <NumericFormat
                  displayType="text"
                  value={
                    record.monthlyRevenue !== undefined
                      ? record.monthlyRevenue.getAmount() / 100
                      : ""
                  }
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />{" "}
                {record.monthlyIncrease !== undefined ? (
                  <>
                    +(
                    <NumericFormat
                      displayType="text"
                      value={record.monthlyIncrease.getAmount() / 100}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    )
                  </>
                ) : (
                  ""
                )}
              </td>
              {stage === 5 ? (
                <>
                  <td>
                    <strong>
                      {record.payback !== undefined
                        ? `${record.payback} Days`
                        : ""}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {record.roi !== undefined ? `${record.roi}x` : ""}
                    </strong>
                  </td>
                </>
              ) : undefined}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export { Projections };
