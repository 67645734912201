import React, { useState, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import {
  Button,
  Table,
  Navbar,
  Container,
  Card,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Dinero from "dinero.js";

import { DataInput } from "./components/DataInput.js";
import { Integration, INTEGRATIONS } from "./components/Integration.js";
import { Projections } from "./components/Projections.js";
import { Cost } from "./components/Cost.js";

import { defaultState, getURLState, setURLState } from "./state.js";
import Icon from "./icons";

const urlState = getURLState();

const logoUrl = new URL("logo.svg", import.meta.url);

function dollarsToCents(dollars) {
  return parseInt((dollars * 100).toFixed(0));
}

function App() {
  const initial = urlState ? urlState : defaultState;

  const [monthlyUsers, setMonthlyUsers] = useState(initial.monthlyUsers);
  const [aov, setAOV] = useState(initial.aov);
  const [conversionRate, setConversionRate] = useState(initial.conversionRate);
  const [stage, setStage] = useState(initial.stage);
  const [integration, setIntegration] = useState(initial.integration);
  const [vehicles, setVehicles] = useState(initial.vehicles);
  const [products, setProducts] = useState(initial.products);
  const [configurations, setConfigurations] = useState(initial.configurations);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setURLState({
        monthlyUsers,
        aov,
        conversionRate,
        stage,
        integration,
        vehicles,
        products,
        configurations,
      });
    }
  }, [
    monthlyUsers,
    aov,
    conversionRate,
    stage,
    integration,
    vehicles,
    products,
    configurations,
  ]);

  const isReady =
    monthlyUsers !== undefined &&
    aov !== undefined &&
    conversionRate !== undefined;

  const monthlyRevenue = isReady
    ? aov.multiply(monthlyUsers * conversionRate)
    : undefined;

  const usage = Object.entries(INTEGRATIONS).reduce((acc, [k, { value }]) => {
    if (!integration[k]) {
      return acc;
    }
    return acc + value;
  }, 0);

  const units = (vehicles || 0) + (products || 0) + (configurations || 0);

  let limit = 50;
  let steps = 0;

  while (units > limit) {
    steps += 1;
    limit *= 1.5;
  }

  const unitCost = new Dinero({ amount: 499 }).subtract(
    new Dinero({ amount: 10 }).multiply(steps),
  );

  const onboarding = new Dinero({ amount: 10000 })
    .multiply(vehicles || 0)
    .add(new Dinero({ amount: 10000 }).multiply(products || 0))
    .add(new Dinero({ amount: 5000 }).multiply(configurations || 0));

  const service = Dinero.maximum([
    unitCost.multiply(
      (vehicles || 0) + (products || 0) + (configurations || 0),
    ),
    new Dinero({ amount: 25000 }),
  ]);

  const cases = [
    {
      name: "Upside (+σ)",
      aov: 2.68,
      conversionRate: 1.15,
      variant: "success",
    },
    { name: "Base (x̄)", aov: 1.57, conversionRate: 0.712, variant: "warning" },
    {
      name: "Downside (-σ)",
      aov: 0.548,
      conversionRate: 0.462,
      variant: "danger",
    },
  ].map((record) => {
    if (!isReady) {
      return record;
    }

    const standard = monthlyRevenue.multiply(1 - usage);
    const protex = aov
      .multiply(1 + record.aov)
      .multiply(
        monthlyUsers * usage * (conversionRate * (1 + record.conversionRate)),
      );
    const projected = standard.add(protex);
    const increase = projected.subtract(monthlyRevenue);

    const payback = (
      onboarding.getAmount() / increase.divide(30).getAmount()
    ).toFixed(0);
    const roi = (increase.getAmount() / service.getAmount()).toFixed(1);

    return {
      ...record,
      monthlyRevenue: projected,
      monthlyIncrease: increase,
      payback: units ? payback : undefined,
      roi: units ? roi : undefined,
    };
  });

  function Next() {
    const DISABLED = {
      1: !isReady,
      2: !usage,
      4: !units,
    };

    const LABELS = {
      1: "Metrics",
      2: "Integrations",
      3: "Projections",
      4: "Cost",
      5: "Return On Investment",
    };

    if (stage !== 0 && LABELS[stage + 1] === undefined) {
      return <></>;
    }

    return (
      <Card bg="light" className="w-full my-5 p-0">
        <Card.Body className="d-flex justify-content-between align-items-center p-2">
          <strong className="ps-2">
            {stage + 1}. {LABELS[stage + 1]}
          </strong>
          <Button
            disabled={DISABLED[stage]}
            variant="dark"
            onClick={() => setStage((v) => v + 1)}
          >
            {stage === 0 ? "Start" : "Continue"}
          </Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Navbar bg="dark">
        <Container>
          <Navbar.Brand className="mx-auto">
            <img height="50" src={logoUrl} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        {stage >= 1 ? (
          <DataInput
            monthlyUsers={monthlyUsers}
            setMonthlyUsers={setMonthlyUsers}
            aov={aov}
            setAOV={setAOV}
            conversionRate={conversionRate}
            setConversionRate={setConversionRate}
            monthlyRevenue={monthlyRevenue}
          />
        ) : undefined}

        {stage >= 2 ? (
          <Integration
            integration={integration}
            setIntegration={setIntegration}
            usage={usage}
          />
        ) : undefined}

        {stage >= 3 ? (
          <Projections cases={cases} usage={usage} stage={stage} />
        ) : undefined}

        {stage >= 4 ? (
          <Cost
            unitCost={unitCost}
            onboarding={onboarding}
            service={service}
            vehicles={vehicles}
            setVehicles={setVehicles}
            products={products}
            setProducts={setProducts}
            configurations={configurations}
            setConfigurations={setConfigurations}
          />
        ) : undefined}

        {stage === 5 ? <div className="pb-5" /> : undefined}

        <Next />
      </Container>
    </>
  );
}

export default App;
