import Dinero from "dinero.js";

import { INTEGRATIONS } from "./components/Integration.js";

function decodeIntegration(v) {
  const result = {};

  for (const [k, { mask }] of Object.entries(INTEGRATIONS)) {
    result[k] = !!(v & mask);
  }

  return result;
}

function encodeIntegration(v) {
  let result = 0;

  for (const [k, { mask }] of Object.entries(INTEGRATIONS)) {
    if (!v[k]) continue;
    result = result | mask;
  }

  return result;
}

export const defaultState = {
  stage: 0,
  integration: { STANDARD: true },
  vehicles: 0,
  products: 0,
  configurations: 0,
};

export function getURLState() {
  const params = new URLSearchParams(window.location.search);

  const monthlyUsers = params.has("monthlyUsers")
    ? parseInt(params.get("monthlyUsers"))
    : null;

  const aov = params.has("aov") ? parseInt(params.get("aov")) : null;

  const conversionRate = params.has("conversionRate")
    ? parseFloat(params.get("conversionRate"))
    : null;

  const stage = params.has("stage") ? parseInt(params.get("stage")) : null;

  const integration = params.has("integration")
    ? parseInt(params.get("integration"))
    : null;

  const vehicles = params.has("vehicles")
    ? parseInt(params.get("vehicles"))
    : null;

  const products = params.has("products")
    ? parseInt(params.get("products"))
    : null;

  const configurations = params.has("configurations")
    ? parseInt(params.get("configurations"))
    : null;

  if (
    monthlyUsers === null ||
    isNaN(monthlyUsers) ||
    aov === null ||
    isNaN(aov) ||
    conversionRate === null ||
    isNaN(conversionRate) ||
    stage === null ||
    isNaN(stage) ||
    integration === null ||
    isNaN(integration) ||
    vehicles === null ||
    isNaN(vehicles) ||
    products === null ||
    isNaN(products) ||
    configurations === null ||
    isNaN(configurations)
  ) {
    return;
  }

  return {
    monthlyUsers,
    aov: new Dinero({ amount: aov }),
    conversionRate,
    stage,
    integration: decodeIntegration(integration),
    vehicles,
    products,
    configurations,
  };
}

export function setURLState(state) {
  const params = new URLSearchParams();

  if (
    state.monthlyUsers === undefined ||
    state.aov === undefined ||
    state.conversionRate === undefined ||
    state.stage === undefined ||
    state.integration === undefined
  ) {
    return;
  }

  params.set("monthlyUsers", state.monthlyUsers);
  params.set("aov", state.aov.getAmount());
  params.set("conversionRate", state.conversionRate);
  params.set("stage", state.stage);
  params.set("integration", encodeIntegration(state.integration));
  params.set("vehicles", state.vehicles || 0);
  params.set("products", state.products || 0);
  params.set("configurations", state.configurations || 0);

  window.history.replaceState({}, "", `${location.pathname}?${params}`);
}
