import { NumericFormat } from "react-number-format";
import { Card, Row, Col, Form } from "react-bootstrap";

function Cost({
  unitCost,
  onboarding,
  service,
  vehicles,
  setVehicles,
  products,
  setProducts,
  configurations,
  setConfigurations,
}) {
  return (
    <>
      <Row className="mt-5 md-gap-5">
        <Col>
          <Form.Group>
            <Form.Label>Vehicles</Form.Label>
            <NumericFormat
              type="tel"
              value={vehicles}
              onValueChange={({ floatValue }) => setVehicles(floatValue)}
              decimalScale={0}
              thousandSeparator={true}
              allowNegative={false}
              className="form-control"
            />
            <Form.Text muted className="d-flex justify-content-between">
              <div>Onboarding $100</div>{" "}
              <div>Service {unitCost.toFormat()}</div>
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Products</Form.Label>
            <NumericFormat
              type="tel"
              value={products}
              onValueChange={({ floatValue }) => setProducts(floatValue)}
              decimalScale={0}
              thousandSeparator={true}
              allowNegative={false}
              className="form-control"
            />
            <Form.Text muted className="d-flex justify-content-between">
              <div>Onboarding $100</div>{" "}
              <div>Service {unitCost.toFormat()}</div>
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Configurations</Form.Label>
            <NumericFormat
              type="tel"
              value={configurations}
              onValueChange={({ floatValue }) => setConfigurations(floatValue)}
              decimalScale={0}
              thousandSeparator={true}
              allowNegative={false}
              className="form-control"
            />
            <Form.Text muted className="d-flex justify-content-between">
              <div>Onboarding $50</div> <div>Service {unitCost.toFormat()}</div>
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5 md-gap-5">
        <Col>
          <Form.Group>
            <Form.Label>One-time Onboarding Cost</Form.Label>
            <NumericFormat
              readOnly
              disabled
              value={onboarding.getAmount() / 100}
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Monthly Service Cost</Form.Label>
            <NumericFormat
              readOnly
              disabled
              value={service.getAmount() / 100}
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export { Cost };
